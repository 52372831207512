p.winner_title {
  font-weight: bold;
  font-size: 3em;
  color: gold;
  margin: 5px 0 50px 0;
}

.card {
  width: 500px;
  border-radius: 5%;
  border: 1px solid rgba(189, 186, 16, 0.05);
  margin: 0 30px;
  box-shadow: 0px 0px 2em rgba(213, 216, 12, 0.5);
}

.card:hover {
  cursor: pointer;
}

.card p {
  font-weight: bold;
  font-size: 1.5em;
  color: #fff7f8;
}

.sneakerImg {
  width: 350px;
  margin-top: 30px;
  border-radius: 5%;
  height: auto;
  transition: transform 0.2s;
}

.card:hover .sneakerImg {
  transform: scale(1.02);
}
