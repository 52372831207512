.App {
  text-align: center;
}

.sneaker-container {
  display: flex;
  flex-wrap: nowrap;
  justify-content: center;
}

.instruction-text {
  color: white;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-weight: bold;
  font-size: 2em;
  text-shadow: 2px 2px 0.1em rgba(0, 0, 0, 0.5);
}

.round-counter {
  color: rgba(0, 0, 0, 0.2);
}

#logo {
  height: 90px;
  margin: 20px 0;
}

#ecipo-logo {
  width: 240px;
}

.cta-text {
  color: darkslategray;
  font-size: 20px;
  font-weight: bold;
}
