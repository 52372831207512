.card {
  max-width: 500px;
  max-height: 500px;
  border-radius: 5%;
  border: 1px solid rgba(0, 128, 128, 0.05);
  margin: 0 30px;
}

.card:hover {
  box-shadow: 0px 0px 2em rgba(164, 66, 0, 0.4);
  cursor: pointer;
}

.card p {
  font-weight: bold;
  font-size: 1.5em;
  color: #fff7f8;
}

.sneakerImg {
  width: 350px;
  margin-top: 10px;
  height: auto;
  border-radius: 5%;
  transition: transform 0.2s;
}

.winner {
  background-color: teal;
}

.card:hover .sneakerImg {
  transform: scale(1.02);
}

@media only screen and (max-width: 600px) {
  .card {
    max-width: 50%;
    border-radius: 5%;
    border: 1px solid rgba(0, 128, 128, 0.05);
    margin: 0 30px;
  }

  .sneakerImg {
    width: 100%;
    margin-top: 10px;
    height: auto;
    border-radius: 5%;
    transition: transform 0.2s;
  }

  .card p {
    font-size: 1em;
  }
}
